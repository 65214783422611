(function(NS, $, undefined) {

	NS.Sandbox.modules.sidebar = function(box) {
		var namespace = box.namespace;

		var ImageCycle = function($element, options) {
			this.$el = $element;
			this.settings = $.extend({}, this.defaults, options);
			this.init();
		};

		ImageCycle.prototype = {
			defaults: {
				delay: '3000',
				fadeDuration: '1000',
				itemListSelector: '.item-list',
				itemSelector: '> .item',
				containerRatio: 1,
			},
			init: function() {
				var me = this;

				me.$itemList = me.$el.find(me.settings.itemListSelector);

				me.buildItemsObject();

				me.refresh();

				me.cycleIndex = 0;
				me.cycleInterval = null;

				if (me.itemsObject.length > 1) me.cycle();
			},
			buildItemsObject: function() {
				var me = this;

				me.itemsObject = me.$itemList.find(me.settings.itemSelector).filter(function() {
					return $(this).find('img').length;
				}).map(function() {
					var $el = $(this);
					var $img = $el.find('img');

					return {
						$el: $el,
						$img: $img,
						aspectRatio: $img.attr('data-aspect-ratio')
					};
				}).get();
			},
			refresh: function() {
				var me = this;

				// Position items
				for (var a = 0; a < me.itemsObject.length; a++) {
					var $el = me.itemsObject[a].$el;
					me.positionItem($el, me.itemsObject[a].$img, me.itemsObject[a].aspectRatio);
				}
			},
			positionItem: function($element, $img, itemRatio) {
				var me = this;
				var cssObj;
				var containerRatio = me.settings.containerRatio;

				if (itemRatio >= containerRatio) { // Landscape
					cssObj = {
						'height': '100%',
						'width': 'auto',
						'max-width': 'none',
						'left': -(((100 * containerRatio / itemRatio) - 100) / 2) + '%'
					};
				} else if (itemRatio < containerRatio) { // Portrait or Square
					cssObj = {
						'width': '100%',
						'height': 'auto',
						'max-width': 'none',
						'top': -(((100 / containerRatio * itemRatio) - 100) / 2) + '%'
					};
				}

				$img.css(cssObj);
			},
			cycle: function() {
				var me = this;

				// If already cycling, kill the cycle
				if (me.cycleInterval) clearInterval(me.cycleInterval);

				// Style first item
				if (me.cycleIndex === 0) me.itemsObject[me.cycleIndex].$el.addClass('active active-in in');
				
				me.cycleInterval = setInterval(function() {
					me.cycleNext();
				}, me.settings.delay);
			},
			cycleTo: function(index) {
				var me = this;
				var newItemObject = me.itemsObject[index];
				var currentItemObject = me.itemsObject[me.cycleIndex];
				var $newItemEl = newItemObject.$el;
				var $currentItemEl = currentItemObject.$el;
				var rf;

				$newItemEl.addClass('active active-in fade');
				rf = $newItemEl[0].offsetWidth;
				$newItemEl.addClass('in');

				var inTransitionEnd = function() {
					$currentItemEl.removeClass('active fade in');
					$newItemEl.removeClass('active-in');
					me.cycleIndex = index;
				};

				$.support.transition ? $newItemEl.one('bsTransitionEnd', inTransitionEnd).emulateTransitionEnd(me.settings.fadeDuration) : inTransitionEnd();
			},
			cycleNext: function() {
				var me = this;

				me.cycleTo((me.cycleIndex + 1 >= me.itemsObject.length) ? 0 : me.cycleIndex + 1);
			}
		};

		// Ready!
		box.ready.done(function() {
			var $article = $('.page-article');
			var $articleBody = $article.find('> .article-body');
			var $articleSidebar = $('.article-sidebar');
			var hasSidebar = $article.hasClass('has-sidebar') && $articleSidebar.length;
			var hasSidebarDOMMoved = false;

			if (hasSidebar) {
				var $widgets = $articleSidebar.find('.sidebar-widget');

				// Build carousel widgets
				$widgets.filter('.sidebar-carousel-widget').each(function() {
					if (!box.sidebarCarouselWidgets) box.sidebarCarouselWidgets = [];
					box.sidebarCarouselWidgets.push(new ImageCycle($(this)));
				});

				// Table of Contents
				var $tableOfContents = $widgets.filter('.sidebar-table-of-contents-widget');

				if ($tableOfContents.length) {
					// Duplicate sidebar for table of contents
					var $tableOfContentsSidebar = $articleSidebar.clone();

					// Remove all other widgets
					$tableOfContentsSidebar.find('.sidebar-widget:not(.sidebar-table-of-contents-widget)').remove();
				}

				// 
				$(window).on('mediamatch.' + namespace, function(ev, newBreakPoint, oldBreakPoint) {
					if (newBreakPoint === 'small' && !hasSidebarDOMMoved) {
						$articleSidebar.insertAfter($articleBody);
						hasSidebarDOMMoved = true;

						if (!$tableOfContents.length) return;
						// Detach OG Table of Contents
						$tableOfContents.detach();
						// Attach new sidebar for mobile
						$tableOfContentsSidebar.insertBefore($articleBody);
					}

					// medium or large (and previously small)
					if (newBreakPoint !== 'small' && hasSidebarDOMMoved) {
						$articleSidebar.insertBefore($articleBody);
						hasSidebarDOMMoved = false;

						if (!$tableOfContents.length) return;
						// Restore OG Table of Contents
						$tableOfContents.prependTo($articleSidebar);
						// Detach
						$tableOfContentsSidebar.detach();
					}
				});
			}
		});
	};

})(window[NS], jQuery);