(function(NS, $, undefined) {

	NS.Sandbox.modules.navigation = function(box) {
		var namespace = box.namespace;
		var $nav = $('#header-nav');
		var navWidth;
		var $hasDropdownItems = $nav.find('.has-dropdown');
		var $dropdowns = $hasDropdownItems.find('> .dropdown');

		$(window).on('resize.' + namespace + 'nav', function() {
			navWidth = $nav.width();
			$dropdowns.children('.dropdown-list').css('max-width', navWidth);
		});

		$nav.on('mouseenter touchend', '.has-dropdown', function(ev) {
			var $this = $(this);
			var $target = $(ev.target);
			var $link = $this.children('a');
			var rf;

			var $dropdown = $this.find('.dropdown');
			
			if (typeof $dropdown.attr('data-closed') !== "undefined") { // If dropdown is closed
				// If the parent link was clicked
				if ($target.is($link)) {
					ev.preventDefault();
				}

				// stop $document handler firing
				ev.stopPropagation();
			} else { // Dropdown open
				return;
			}

			var outTimeout = $dropdown.data('outTimeout');
			if (outTimeout) clearTimeout(outTimeout);

			$dropdown.removeAttr('data-closed');

			var navItemOffset = $this.position();
			var navItemWidth = $this.outerWidth(true);
			var navItemCenter = navItemOffset.left + (navItemWidth / 2);
			var dropdownWidth = $dropdown.outerWidth(true);
			
			if ((dropdownWidth / 2) > navItemCenter) { // Extends past the left viewport edge
				$dropdown.css('marginLeft', (dropdownWidth / 2) - navItemCenter);
			} else if (navWidth < navItemCenter + (dropdownWidth / 2)) { // Extends past the right viewport edge
				$dropdown.css('marginLeft', navWidth - (navItemCenter + (dropdownWidth / 2)));
			}

			rf = $dropdown[0].offsetWidth;

			$dropdown.addClass('in');
		});

		$nav.on('mouseleave', '.has-dropdown', function(ev) {
			var $this = $(this);
			var rf;
			var $dropdown = $this.find('.dropdown');

			var outTimeout = $dropdown.data('outTimeout');
			if (outTimeout) clearTimeout(outTimeout);

			// Bail out if not open/already closing
			if (typeof $dropdown.attr('data-closed') !== "undefined") return false;

			$dropdown
				.removeClass('in')
				.data('outTimeout', setTimeout(function() {
					$dropdown
						.attr('data-closed', '')
						.css('marginLeft', '');
				}, 125));
		});

		$(document).on('touchend', function(ev) {
			var $target = $(ev.target);

			var $dropdownsThatAreOpen = $hasDropdownItems.filter(function(i) {
				return typeof $(this).children('.dropdown').attr('data-closed') === "undefined";
			});

			if (!$dropdownsThatAreOpen.length) return;

			// Did event happen inside a .has-dropdown nav item?
			var $dropdownNavItemThatHadTouchEventInOrOnIt = $hasDropdownItems.filter(function(i) {
				var $this = $(this);
				var isOpen = typeof $this.children('.dropdown').attr('data-closed') === "undefined";
				var targetInside = $.contains(this, ev.target);
				//var targetIsNavLink = $this.children('a').is($target);

				return isOpen && ($this.is($target) || $.contains(this, ev.target));
			});

			// Event was link inside the .dropdown, hide it
			if (!$dropdownNavItemThatHadTouchEventInOrOnIt.children('a').is($target)) $dropdownsThatAreOpen.trigger('mouseleave');

			// Event was inside the .has-dropdown nav item
			if ($dropdownNavItemThatHadTouchEventInOrOnIt.length) return;

			ev.preventDefault();
			ev.stopPropagation();

			// Event got cancelled!
		});

			
		// Ready!
		box.ready.done(function() {});
	};

})(window[NS], jQuery);

$( document ).ready(function() {
	console.log('Ready')
	
	$("#top").click(function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		console.log('ScrolltoTop')
	});
	
});

