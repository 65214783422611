(function(NS, $, undefined) {

	NS.Sandbox.modules.mq = function(box) {
		var ns = box.namespace;

		var queryCache = new Array(2);
		
		box.getMediaQuery = function(history) {
			return history ? queryCache : queryCache[0];
		};

		$(window).on('resize.' + ns + '.media', function(ev) {
			var breakpoint;

			// Get the value of html { font-family } from the element style.
			if (document.documentElement.currentStyle) {
				breakpoint = document.documentElement.currentStyle["fontFamily"];
			}

			if (window.getComputedStyle) {
				breakpoint = window.getComputedStyle(document.documentElement, null).getPropertyValue('font-family');
			}

			// No support for CSS enumeration? Return and avoid errors.
			if (breakpoint === null) return;

			// Android browsers place a "," after an item in the font family list.
			// Most browsers either single or double quote the string.
			breakpoint = breakpoint.replace(/['",]/g, '');
			if (breakpoint !== queryCache[0]) {
				queryCache.pop();
				queryCache.unshift(breakpoint);

				// Trigger unmatch
				if (queryCache[1]) $(window).trigger('mediaunmatch', [queryCache[1]]);

				// Trigger match
				$(window).trigger('mediamatch', [queryCache[0], queryCache[1]]);
			}
		});

		$(window).on('orientationchange.' + ns + '.media', function() {
			$(this).trigger('resize');
		});

		// Ready!
		box.ready.done(function() {
			$(window).trigger('resize.' + ns + '.media');
		});
	};

})(window[NS], jQuery);