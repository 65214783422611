(function(NS, $, undefined) {

	NS.Sandbox.modules.layout = function(box) {
		var namespace = box.namespace;

		// Ready! aka all modules have been initialised
		box.ready.done(function() {
			var $article = $('.page-article');

			// FitVids
			$article.fitVids();

			// Google Maps
			$article.find('[data-map-id]').each(function() {
				var $this = $(this),
					mapId = $this.attr('data-map-id'),
					options = NS.maps[mapId];

				if (typeof options !== 'undefined') {
					$this.googleMap(options);
				}
			});

			// Add class for linked images (to remove inherited underlines)
			$article.find('.article-body .chunk-body img').each(function() {
				var $parent = $(this).parent('a');
				if ( $parent.length ) {
					$parent.addClass('image-link');
				}
			});

			// Gallery
			$article.find('.chunk-gallery').each(function() {
				var $this = $(this)
				,	$hero = $this.find('.chunk-hero')
				,	$heroCarousel = $hero.find('.hero-carousel')
				,	heroOwlInstance
				,	$thumbsCarousel = $hero.find('.thumbs-carousel')
				,	thumbsOwlInstance;
				
				heroOwlInstance = $heroCarousel
					.owlCarousel({
						nav: true,
						navText: [
							'←', 
							'→'
						],
						lazyLoad : true,
						loop: true,
						responsive: {
							0: {
								items: 1
							}
						}
					})
					.on("changed.owl.carousel", function(e) {
						if (e.property.name === "position") {
							thumbsOwlInstance.to(e.relatedTarget.relative(e.property.value, true));
						}
					})
					.data('owl.carousel');

				thumbsOwlInstance = $thumbsCarousel
					.owlCarousel({
						lazyLoad : true,
						loop: true,
						center: true,
						margin: 16,
						responsive: {
							0: {
								items: 3
							},
							1024: {
								items: 5
							}
						}
					})
					.on("changed.owl.carousel", function(e) {
						if (e.property.name === "position") {
							heroOwlInstance.to(e.relatedTarget.relative(e.property.value, true));
						}
					})
					.on("click.owl.carousel", 'img', function(e) {
						var index = $(e.target).parents(".owl-item").index();
						heroOwlInstance.to(thumbsOwlInstance.relative(index, true));
					})
					.data('owl.carousel');
			});
		});
	};

})(window[NS], jQuery);