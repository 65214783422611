(function(NS, $, undefined) {
	var Sandbox = function() {
		// turning arguments into an array
		var args = Array.prototype.slice.call(arguments),
			// the last argument is the callback
			callback = args.pop(),
			// modules can be passed as an array or as individual parameters
			modules = (args[0] && typeof args[0] === "string") ? args : args[0],
			i;

		// make sure the function is called as a constructor
		if (!(this instanceof Sandbox)) {
			return new Sandbox(modules, callback);
		}

		// now add modules to the core 'this' object
		// no modules or "*" both mean "use all modules"
		if (!modules || modules[0] === '*') {
			modules = [];
			for (i in Sandbox.modules) {
				if (Sandbox.modules.hasOwnProperty(i)) {
					modules.push(i);
				}
			}
		}

		var deferred = $.Deferred();
		this.ready = deferred.promise();

		// initialize the required modules
		for (i = 0; i < modules.length; i += 1) {
			Sandbox.modules[modules[i]](this);
		}

		// call the callback
		callback(this);

		deferred.resolve();
	};

	var googleMapsAPIDeferred = $.Deferred();
	var googleMapsAPIReadyDeferred = $.Deferred();

	googleMapsAPIDeferred.done(function(key) {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=' + key + '&sensor=false' + '&callback=gmapapiloaded';
		document.body.appendChild(script);

		// Callback
		window.gmapapiloaded = function() {
			googleMapsAPIReadyDeferred.resolve();
		};
	});

	// any prototype properties as needed
	Sandbox.prototype = {
		namespace: NS.namespace,
		googleAPIKey: googleMapsApiKey,
		mapMarker: 'i/map-marker.png',
		loadGoogleMapsAPI: googleMapsAPIDeferred,
		googleMapsAPIReady: googleMapsAPIReadyDeferred
	};

	Sandbox.modules = {};

	Sandbox.defaults = {};

	NS.Sandbox = Sandbox;
	
})(window[NS], jQuery);