(function(NS, $, undefined) {

	NS.Sandbox.modules.map = function(box) {
		var namespace = box.namespace;

		box.googleMapType = namespace;
		// http://gmaps-samples-v3.googlecode.com/svn/trunk/styledmaps/wizard/index.html
		box.googleMapStyles = [{
			"featureType": "administrative",
			"stylers": [{
				"visibility": "simplified"
			}]
		}, {
			"featureType": "poi",
			"stylers": [{
				"visibility": "off"
			}]
		}, {
			"elementType": "labels.text.fill",
			"stylers": [{
				"color": "#222222"
			}]
		}, {
			"featureType": "landscape",
			"elementType": "geometry",
			"stylers": [{
				"visibility": "on"
			}, {
				"color": "#dddddd"
			}]
		}, {
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [{
				"color": "#ffffff"
			}, {
				"visibility": "simplified"
			}]
		}, {
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [{
				"visibility": "off"
			}]
		}, {
			"featureType": "transit",
			"elementType": "labels.icon",
			"stylers": [{
				"visibility": "off"
			}]
		}, {}];

		var GoogleMap = function(element, options) {
			var me = this;

			this.$el = $(element);
			this.options = $.extend(true, {}, this.defaults, options);

			if (!window.google || !google.maps) {
				box.loadGoogleMapsAPI.resolve(box.googleAPIKey);
			}

			box.googleMapsAPIReady.done(function() {
				me._init();
			});
		};

		GoogleMap.prototype = {
			defaults: {
				zoom: 17,
				disableDefaultUI: true,
				scrollwheel: false,
				disableDoubleClickZoom: true,
				keyboardShortcuts: false,
				panControl: false,
				draggable: false,
				backgroundColor: '#ffffff',
				mapTypeId: box.googleMapType
			},
			_init: function() {
				var me = this;

				if (!me.options.center && !me.options.markers) me.destroy();

				me.$mapEl = me.$el.find('> .liquid-positioning');

				me._buildMap();
			},
			_buildMap: function() {
				var me = this,
					opts = me.options;

				// Generate center position
				var centerLatLng;

				if (opts.center) {
					centerLatLng = new google.maps.LatLng(opts.center.lat, opts.center.lng);
				} else if (opts.markers.length === 1) {
					centerLatLng = new google.maps.LatLng(opts.markers[0].lat, opts.markers[0].lng);
				}

				// Extend map options with map styles, doing this here because it guarantees google.maps is ready
				opts = $.extend(opts, {
					center: centerLatLng,
					mapTypeControlOptions: {
						mapTypeIds: [google.maps.MapTypeId.ROADMAP, box.googleMapType]
					}
				});

				// Build map
				me.map = new google.maps.Map(me.$mapEl[0], opts);

				// console.log(me.map);

				// Add map style
				me.map.mapTypes.set(box.googleMapType, new google.maps.StyledMapType(box.googleMapStyles, {
					name: namespace + '-style'
				}));

				// Add markers to map
				if (opts.markers) {
					var bounds = new google.maps.LatLngBounds(),
						addMarkerListener = function(marker) {
							google.maps.event.addListener(marker, 'click', function() {
								window.open('http://google.com/maps?q=' + this.getPosition().toUrlValue());
							});
						};

					for (var a = 0; a < opts.markers.length; a++) {
						var markerItem = opts.markers[a],
							latLng = new google.maps.LatLng(markerItem.lat, markerItem.lng);

						bounds.extend(latLng);

						var marker = new google.maps.Marker({
							animation: google.maps.Animation.DROP,
							map: me.map,
							position: latLng,
							clickable: markerItem.clickable ? true : false,
							title: markerItem.title ? markerItem.title : '',
							icon: {
								url: box.mapMarker,
								size: new google.maps.Size(44, 62),
								origin: new google.maps.Point(0, 0),
								anchor: new google.maps.Point(22, 62),
								scaledSize: new google.maps.Size(44, 62)
							},
							shape: {
								//native coords, before map marker is scaled by 50%
								//coord: [4, 44, 10, 24, 16, 16, 24, 10, 44, 4, 64, 10, 72, 16, 78, 24, 84, 44, 81, 58, 68, 82, 44, 120, 20, 82, 7, 58],
								coord: [2, 22, 5, 12, 8, 8, 12, 5, 22, 2, 32, 5, 36, 8, 39, 12, 42, 22, 40.5, 29, 34, 41, 22, 60, 10, 41, 3.5, 29],
								type: 'poly'
							}
						});

						addMarkerListener(marker);
					}

					if (!opts.center) me.map.fitBounds(bounds);
				}
			},
			destroy: function() {
				var me = this;
				$.removeData(me.$el[0], 'googleMap');
			}
		};

		// jQuery plugin constructor
		$.bridget('googleMap', GoogleMap);
	};

})(window[NS], jQuery);